import Cookies from 'js-cookie'
import { useCookies } from 'react-cookie'
import { decodeToken, isExpired } from 'react-jwt'
import { DecodeToken } from 'ts/interfaces/app.interfaces'

const verifyRoles = (roles: any) => {
  let roleSelected = 'client'

  roles &&
    roles.forEach((role: string) => {
      const roleCheck = role.toLowerCase()

      if (roleCheck === 'admin') {
        roleSelected = roleCheck
      }

      if (roleCheck === 'premiumclient') {
        roleSelected = roleCheck
      }
    })

  return roleSelected
}

interface DataToken {
  userName: string
  roles: string[]
  role: string
  isMyTokenExpired: boolean
  expired: number | null
  id: string
}

const setTenantId = (tenantId) => {
  Cookies.set('tenantId', tenantId)
}

const useCheckToken = () => {
  const [cookies] = useCookies(['token', 'tenantId'])
  const { token, tenantId } = cookies
  console.log('token', token)
  // const tokenDecoded: DecodeToken | any = decodeToken(token)
  // const isMyTokenExpired = isExpired(token ?? '')
  // const roles: string[] = typeof tokenDecoded.role === 'string' ? [tokenDecoded.role] : tokenDecoded.role
  // const role = tokenDecoded && verifyRoles(roles)
  let dataToken: DataToken = {
    userName: '',
    roles: [],
    role: 'client',
    isMyTokenExpired: true,
    expired: null,
    id: '',
  }

  // if (tokenDecoded) {
  //   dataToken = {
  //     userName: tokenDecoded.sub,
  //     roles: tokenDecoded.role,
  //     role,
  //     isMyTokenExpired,
  //     expired: tokenDecoded.exp,
  //     id: tokenDecoded.jti,
  //   }
  // }

  return {
    userName: 'Admin', // dataToken.userName,
    role: 'Admin',
    // roles: dataToken.role,
    isMyTokenExpired: false,
    // expired: dataToken.expired,
    id: '001',
    tenantId,
    setTenantId,
  }
}

export default useCheckToken
