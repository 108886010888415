import { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { RootState, UserObject } from 'redux/interfaces'
import { useTranslation } from 'react-i18next'
import RoleConditionalRender from 'shared/components/roleConditionRender/roleConditionRender.component'
import useCheckToken from 'shared/hooks/useCheckToken.hook'

import './sidebar.style.scss'

export const Sidebar = () => {
  const [openSubmenu, setOpenSubmenu] = useState<Record<string, boolean>>({
    competitionDictionary: false,
    letter: false,
    payment: false,
  })
  const { userName } = useCheckToken()
  const [user, setUser] = useState<UserObject | null>()
  const userRedux = useSelector((state: RootState) => state.user)
  const [t] = useTranslation('global')
  // const dispatch = useDispatch()

  useEffect(() => {
    setUser(userRedux?.data)
  }, [userRedux])

  const handleOpenSubmenu = (submenu: string) => {
    setOpenSubmenu((prevState) => ({
      ...prevState,
      [submenu]: !prevState[submenu],
    }))
  }

  return (
    <aside className='main-sidebar sidebar-light-primary elevation-4'>
      {/* <!-- Brand Logo --> */}
      <Link to='./' className='brand-link pl-4'>
        {/* <span className='brand-image img-circle elevation-3' style={{ paddingTop: '4px', height: '28px', fontWeight: '900' }}>
          HRX
        </span> */}
        {/* <img src='dist/img/AdminLTELogo.png' alt='AdminLTE Logo' className='brand-image img-circle elevation-3' style={{ opacity: 0.8 }} /> */}
        <span className='brand-text font-weight-light'>
          <h3>Vanesa y Fernando</h3>
          {/* <img src={require('assets/img/logoHRXpert.png')} alt='HRXpert Logo' style={{ width: '130px', height: '30px' }} /> */}
        </span>
      </Link>

      {/* <!-- Sidebar --> */}
      <div className='sidebar p-0'>
        {/* <!-- Sidebar user panel (optional) --> */}
        <div className='user-panel py-2 d-flex align-items-center'>
          <i className='fas fa-user-tie border border-secondary ml-3 p-2 rounded-circle text-secondary' style={{ fontSize: 30 }}></i>
          <div className='info'>
            <Link to='/profile' className='d-block font-weight-bold text-dark'>
              {userName}
            </Link>
          </div>
        </div>

        {/* <!-- Sidebar Menu --> */}
        <nav className='mt-2 mx-1'>
          <ul className='nav nav-pills nav-sidebar flex-column' data-widget='treeview' role='menu' data-accordion='false'>
            {/* <!-- Add icons to the links using the .nav-icon className
               with font-awesome or any other icon font library --> */}
            <RoleConditionalRender
              admin={
                <>
                  <li className='nav-item'>
                    <Link to='/dashboard' className='nav-link'>
                      <i className='nav-icon fas fa-chart-pie' />
                      <p>{t('sidebar.dashboard')}</p>
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link to='/tenant' className='nav-link'>
                      <i className='nav-icon fa fa-industry' />
                      <p>{t('sidebar.tenants')}</p>
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link to='/categoryEvaluator' className='nav-link'>
                      <i className='nav-icon fa fa-sitemap' />
                      <p>{t('sidebar.evaluator-categories')}</p>
                    </Link>
                  </li>
                </>
              }
              client={
                <li className='nav-item'>
                  <Link to='/dashboard' className='nav-link'>
                    <i className='nav-icon fas fa-chart-pie' />
                    <p>{t('sidebar.dashboard')}</p>
                  </Link>
                </li>
              }
            />
            <RoleConditionalRender
              admin={
                <>
                  <li className='nav-item'>
                    <Link to='/users' className='nav-link'>
                      <i className='nav-icon fas fa-users' />
                      <p>{t('sidebar.users')}</p>
                    </Link>
                  </li>
                  {/* <li className='nav-item'>
                    <Link to='/users/role' className='nav-link'>
                      <i className='nav-icon fas fa-users' />
                      <p>{t('sidebar.role-family-assignment')}</p>
                    </Link>
                  </li> */}
                  <li className='nav-item'>
                    <Link to='/assignQuestionnaire' className='nav-link'>
                      <i className='nav-icon fa fa-link' />
                      <p>{t('sidebar.questionnaire-assignments')}</p>
                    </Link>
                  </li>
                </>
              }
              client={
                <>
                  <li className='nav-item'>
                    <Link to='/users/role' className='nav-link'>
                      <i className='nav-icon fas fa-users' />
                      <p>{t('sidebar.role-family-assignment')}</p>
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link to='/assignQuestionnaire/user' className='nav-link'>
                      <i className='nav-icon fa fa-link' />
                      <p>{t('sidebar.questionnaire-assignments')}</p>
                    </Link>
                  </li>
                </>
              }
            />
            <RoleConditionalRender
              admin={
                <li className={openSubmenu.letter ? 'nav-item menu-is-opening menu-open' : 'nav-item'}>
                  <button type='button' onClick={() => handleOpenSubmenu('letter')} className='nav-link btn btn-link'>
                    <i className='nav-icon fas fa-envelope' />
                    <p>
                      {t('sidebar.letters')}
                      <i className='right fas fa-angle-left' />
                    </p>
                  </button>
                  <ul id='letter' className='nav nav-treeview'>
                    <li className='nav-item'>
                      <Link to='/letterComposition' className='nav-link'>
                        <i className='nav-icon fas fa-mail-bulk' />
                        <p>{t('sidebar.letter-compositions')}</p>
                      </Link>
                    </li>
                    <li className='nav-item'>
                      <Link to='/letter' className='nav-link'>
                        <i className='nav-icon fas fa-envelope-open-text' />
                        <p>{t('sidebar.letters')}</p>
                      </Link>
                    </li>
                    <li className='nav-item'>
                      <Link to='/distributionList' className='nav-link'>
                        <i className='fas fa-at nav-icon' />
                        <p>{t('sidebar.mail-distributions')}</p>
                      </Link>
                    </li>
                    <li className='nav-item'>
                      <Link to='/schedule' className='nav-link'>
                        <i className='fas fa-calendar-check nav-icon' />
                        <p>{t('sidebar.schedulers')}</p>
                      </Link>
                    </li>
                  </ul>
                </li>
              }
              client={null}
            />
            <RoleConditionalRender
              client={null}
              admin={
                <li className={openSubmenu.competitionDictionary ? 'nav-item menu-is-opening menu-open' : 'nav-item'}>
                  <button type='button' onClick={() => handleOpenSubmenu('competitionDictionary')} className='nav-link btn btn-link'>
                    <i className='nav-icon fa fa-server' />
                    <p>
                      {t('sidebar.skills-library')}
                      <i className='right fas fa-angle-left' />
                    </p>
                  </button>
                  <ul id='threeSixty' className='nav nav-treeview'>
                    <li className='nav-item'>
                      <Link to='/family' className='nav-link'>
                        <i className='nav-icon fas fa-mail-bulk' />
                        <p>{t('sidebar.families')}</p>
                      </Link>
                    </li>
                    <li className='nav-item'>
                      <Link to='/role' className='nav-link'>
                        <i className='nav-icon fas fa-mail-bulk' />
                        <p>{t('sidebar.roles')}</p>
                      </Link>
                    </li>
                    <li className='nav-item'>
                      <Link to='/clusterType' className='nav-link'>
                        <i className='nav-icon fas fa-mail-bulk' />
                        <p>{t('sidebar.clusterTypes')}</p>
                      </Link>
                    </li>
                    <li className='nav-item'>
                      <Link to='/cluster' className='nav-link'>
                        <i className='nav-icon fas fa-mail-bulk' />
                        <p>{t('sidebar.clusters')}</p>
                      </Link>
                    </li>
                    <li className='nav-item'>
                      <Link to='/factor' className='nav-link'>
                        <i className='nav-icon fas fa-mail-bulk' />
                        <p>{t('sidebar.factors')}</p>
                      </Link>
                    </li>
                    <li className='nav-item'>
                      <Link to='/level' className='nav-link'>
                        <i className='nav-icon fas fa-mail-bulk' />
                        <p>{t('sidebar.levels')}</p>
                      </Link>
                    </li>
                    {/* <li className='nav-item'>
                      <Link to='/customQuestions' className='nav-link'>
                        <i className='nav-icon fas fa-mail-bulk' />
                        <p>Preguntas Custom</p>
                      </Link>
                    </li> */}
                    {/* <li className='nav-item'>
                      <Link to='/questionnaire' className='nav-link'>
                        <i className='nav-icon fas fa-th' />
                        <p>{t('sidebar.questionnaires')}</p>
                      </Link>
                    </li> */}
                    <li className='nav-item'>
                      <Link to='/configurationThreeSixty' className='nav-link'>
                        <i className='nav-icon fas fa-mail-bulk' />
                        <p>{t('sidebar.questionnaires-360')}</p>
                      </Link>
                    </li>
                    <li className='nav-item'>
                      <Link to='/questionnaires' className='nav-link'>
                        <i className='nav-icon fas fa-mail-bulk' />
                        <p>{t('sidebar.questionnaires')}</p>
                      </Link>
                    </li>
                    {/* <li className='nav-item'>
                      <Link to='/questionView' className='nav-link'>
                        <i className='nav-icon fas fa-mail-bulk' />
                        <p>Vista Cuestionario</p>
                      </Link>
                    </li> */}
                    {/* <li className='nav-item'>
                      <Link to='/configurationQuestionnaire' className='nav-link'>
                        <i className='nav-icon fas fa-mail-bulk' />
                        <p>Configure Questionnaire</p>
                      </Link>
                    </li> */}
                  </ul>
                </li>
              }
            />
            <RoleConditionalRender
              admin={
                <li className={openSubmenu.payment ? 'nav-item menu-is-opening menu-open' : 'nav-item'}>
                  <button type='button' onClick={() => handleOpenSubmenu('payment')} className='nav-link btn btn-link'>
                    <i className='nav-icon fas fa-money-bill' />
                    <p>
                      {t('sidebar.payment')}
                      <i className='right fas fa-angle-left' />
                    </p>
                  </button>
                  <ul className='nav nav-treeview'>
                    <li className='nav-item'>
                      <Link to='/subscriptions' className='nav-link'>
                        <i className='nav-icon fas fa-handshake' />
                        <p>{t('sidebar.subscriptions')}</p>
                      </Link>
                    </li>
                    <li className='nav-item'>
                      <Link to='/products' className='nav-link'>
                        <i className='nav-icon fas fa-shopping-cart' />
                        <p>{t('sidebar.products')}</p>
                      </Link>
                    </li>
                    <li className='nav-item'>
                      <Link to='/plans' className='nav-link'>
                        <i className='nav-icon fas fa-shopping-cart' />
                        <p>{t('sidebar.plans')}</p>
                      </Link>
                    </li>
                  </ul>
                </li>
              }
              client={null}
            />
          </ul>
        </nav>
        {/* <!-- /.sidebar-menu --> */}
      </div>
      {/* <!-- /.sidebar --> */}
    </aside>
  )
}

export default memo(Sidebar)
