import { ContentHeader } from 'shared/components/contentHeader/contentHeader.component'
import { useTranslation } from 'react-i18next'
import { deleteGuest, useGetGuests } from 'api/questionnaire/questionnaire.api'
import { Button, CircularProgress } from '@mui/material'
import DataTable from 'shared/components/dataTable/dataTable.component'
// import useCheckToken from 'shared/hooks/useCheckToken.hook'
import { GridColDef } from '@mui/x-data-grid'
import { getClassAsPercentage, getLabelNotification } from 'shared/helpers'
import DeleteIcon from '@mui/icons-material/DeleteRounded'
import EditIcon from '@mui/icons-material/EditRounded'
import { Link, useNavigate } from 'react-router-dom'
import { pushNotification } from 'shared/helpers/notifications/notifications'

export const Dashboard = () => {
  const [t] = useTranslation(['global'])
  const navigate = useNavigate()

  const { data: guests, isLoading, refetch } = useGetGuests()

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Nombre Invitado',
      type: 'string',
      headerAlign: 'center',
      align: 'center',
      width: 300,
      renderCell: (params) => params.row.name,
    },
    {
      field: 'tickets',
      headerName: 'Boletos',
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      width: 100,
      renderCell: (params) => params.row.tickets,
    },
    {
      field: 'confirm',
      headerName: 'Confirmado',
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      width: 100,
      renderCell: (params) => (
        <span className={`badge badge-${params.row.confirm ? 'success' : 'danger'}`} title={`${params.row.confirm ? 'Confirmado' : 'No Confirmado'}`}>
          {`${params.row.confirm ? 'Confirmado' : 'No Confirmado'}`}
        </span>
      ),
    },
    {
      field: 'userKey',
      headerName: 'Clave de Usuario',
      type: 'string',
      headerAlign: 'center',
      align: 'center',
      width: 200,
      renderCell: (params) => (
        <a target='_blank' href={`http://vaneyfer.istyle.mx//?invite=/${params.row._id}`}>
          {params.row._id}
        </a>
      ),
    },
    {
      field: 'edit',
      headerName: 'Editar',
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const userId: string = params.row._id
        return (
          <Button
            onClick={() => {
              navigate(`/guests/edit/${userId}`)
            }}
          >
            <EditIcon />
          </Button>
        )
      },
    },
    {
      field: 'delete',
      headerName: 'Eliminar',
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const userId: string = params.row._id
        return (
          <Button
            onClick={() => {
              deleteGuest(userId).then((resp) => {
                if (resp.status === 200) {
                  refetch()
                  pushNotification(resp.data.statusText, 'success')
                } else {
                  pushNotification('Algo salio mal al eliminar', 'error')
                }
              })
            }}
          >
            <DeleteIcon />
          </Button>
        )
      },
    },
  ]

  if (isLoading) return <CircularProgress />

  return (
    <>
      <ContentHeader title={t('dashboard.header')} breadCrumb={{ home: t('sidebar.home'), page: t('sidebar.dashboard') }} />

      <section className='content'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-3 col-6'>
              <div className='small-box bg-info'>
                <div className='inner'>
                  <h3>{guests.data?.length ?? 0}</h3>
                </div>
                <div className='icon'>
                  <i className='ion ion-bag'></i>
                </div>
                <a href='#' className='small-box-footer'>
                  Invitados
                </a>
              </div>
            </div>
            <div className='col-lg-3 col-6'>
              <div className='small-box bg-success'>
                <div className='inner'>
                  <h3>{guests.data?.reduce((acc, curr) => acc + curr.tickets, 0)}</h3>
                </div>
                <div className='icon'>
                  <i className='ion ion-stats-bars'></i>
                </div>
                <a href='#' className='small-box-footer'>
                  Boletos
                </a>
              </div>
            </div>
            <div className='col-lg-3 col-6'>
              <div className='small-box bg-success'>
                <div className='inner'>
                  <h3>{guests.data?.reduce((acc, curr) => acc + (curr.confirm && curr.tickets), 0)}</h3>
                </div>
                <div className='icon'>
                  <i className='ion ion-person-add'></i>
                </div>
                <a href='#' className='small-box-footer'>
                  Boletos Confirmados
                </a>
              </div>
            </div>
            <div className='col-lg-3 col-6'>
              <div className='small-box bg-danger'>
                <div className='inner'>
                  <h3>{guests.data?.reduce((acc, curr) => acc + (!curr.confirm && curr.tickets), 0)}</h3>
                </div>
                <div className='icon'>
                  <i className='ion ion-pie-graph'></i>
                </div>
                <a href='#' className='small-box-footer'>
                  Boletos No Confirmados
                </a>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='card col'>
              <div className='card-header'>
                {t('dashboard.assigned-questionnaires-header')}
                <div className='card-tools'>
                  <Link to='/guests/new'>
                    <span className='badge badge-primary'>Crear Invitado</span>
                  </Link>
                </div>
              </div>
              <div className='card-body'>
                <DataTable
                  data={guests?.data ?? []}
                  columns={columns}
                  getRowId={(row) => row._id}
                  totalRows={guests.data?.length ?? 0}
                  pageSize={30}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
